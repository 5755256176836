/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  form: {
    '& > div': {
      marginBottom: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3),
    },
  },
}));
