import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import get from 'lodash/get';

import SEO from 'components/seo';
import { ROUTES } from 'assets/data/actpsicologos-app';
import AppLayout from 'app/AppLayout/AppLayout';
import AppLegalInfo from 'app/AppLegalInfo';
import Notice from 'components/Notice/Notice';
import useStyles from './Contact.styles';

const Contact = ({ location }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [noticeEmailSent, setNoticeEmailSent] = useState();
  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    defaultValues: {
      acceptPolicy: false,
      email: '',
      message: get(location, 'state.message', ''),
      name: '',
    },
  });

  const onSubmit = data => {
    emailjs.send('service_o1u1g7m', 'template_I8zrVmus', data, 'user_gulh3U6n8De6QGDHs78Cl')
      .then(result => {
        setNoticeEmailSent('success');
        reset();
      },
      error => {
        setNoticeEmailSent('error');
        reset();
      });
  };

  const renderEmailSentNotice = () => {
    if (!noticeEmailSent) {
      return null;
    }
    if (noticeEmailSent === 'error') {
      const emailSentTexts = t('base.emailSent.error');
      return (
        <Notice
          description={emailSentTexts.description}
          title={emailSentTexts.title}
          type="error"
        />
      );
    }
    const emailSentTexts = t('base.emailSent.success');
    return (
      <Notice
        description={emailSentTexts.description}
        title={emailSentTexts.title}
        type="success"
      />
    );
  };

  let errorEmail;
  if (errors.email) {
    errorEmail = errors.email.type === 'pattern'
      ? t('forms.contact.email.validations.format')
      : t('forms.contact.email.validations.required');
  }

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: t('routes.contact.title'),
      }}
    >
      <SEO
        description={t('routes.contact.meta.description')}
        title={t('routes.contact.meta.title')}
      />
      <Container className={classes.root} maxWidth="lg">
        <Typography className={classes.description} variant="body1">
          {t('routes.contact.description')}
        </Typography>
        {renderEmailSentNotice()}
        <Container maxWidth="sm">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.form}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoFocus
                    error={errors.name}
                    helperText={errors.name && t('forms.contact.name.validations.required')}
                    label={t('forms.contact.name.label')}
                    variant="outlined"
                  />
                )}
                rules={{
                  required: true,
                }}
              />
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={Boolean(errorEmail)}
                    helperText={errorEmail}
                    label={t('forms.contact.email.label')}
                    variant="outlined"
                  />
                )}
                rules={{
                  pattern: /^\S+@\S+\.\S+$/,
                  required: true,
                }}
              />
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.message}
                    helperText={errors.message && t('forms.contact.message.validations.required')}
                    label={t('forms.contact.message.label')}
                    maxRows={15}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                )}
                rules={{
                  required: true,
                }}
              />
              <AppLegalInfo />
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Controller
                        control={control}
                        name="acceptPolicy"
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    )}
                    label={(
                      <Box>
                        <Trans
                          i18nKey="forms.contact.policy.label"
                          values={{}}
                        >
                          {[
                            <Link
                              key={0}
                              className={classes.link}
                              target="_blank"
                              to={ROUTES.PRIVACY_POLICY}
                            />,
                          ]}
                        </Trans>
                      </Box>
                    )}
                  />
                </FormGroup>
                {errors.acceptPolicy && (
                  <FormHelperText error>
                    {t('forms.contact.policy.validations.required')}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                className={classes.button}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                {t('routes.contact.submit')}
              </Button>
            </Box>
          </form>
        </Container>
      </Container>
    </AppLayout>
  );
};

Contact.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      message: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Contact;
